<template>
  <div class="PendingMembersTable responsive-table">
    <table class="table is-full has-cells-vertically-centered">
      <thead class="has-background-grey-lighter is-size-6 no-hover">
        <tr>
          <th class="has-text-left">
            {{ $t('forms.email_no_dash') }}
          </th>
          <th class="is-narrow">
            {{ $t('components.brand_management.read_only') }}
          </th>
          <th class="is-narrow has-text-centered">
            {{ $t('components.pending_members_table.last_update') }}
          </th>
          <th class="is-narrow has-text-centered">
            {{ $t('general.actions') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="invitation in invitations" :key="invitation.email">
          <td>
            {{ invitation.email }}
          </td>
          <td class="has-text-centered">
            <round-icon v-if="invitation.read_only" type="check" class="border-grey" />
          </td>
          <td class="has-nowrap">
            {{ datetime(invitation.updated_at) }}
          </td>
          <td>
            <div class="is-flex">
              <v-button
                v-if="!invitation.accepted"
                :loading="isResending === invitation.id"
                :title="$t('components.pending_members_table.resend_invitation')"
                class="is-narrow is-primary is-plain"
                data-testid="resendActivation"
                @click="resendInvitation(invitation)"
              >
                <v-icon type="refresh" />
              </v-button>
              <v-button
                v-if="!invitation.accepted"
                :loading="isCancelling === invitation.id"
                class="is-narrow is-black is-plain"
                data-testid="cancelActivation"
                @click="cancelInvitation(invitation)"
              >
                {{ $t('forms.cancel') }}
              </v-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { BrandApiService } from '@hypefactors/shared/js/services/api/BrandApiService'
import DatetimeMixin from '@hypefactors/shared/js/mixins/DatetimeMixin'

/**
 * @module PendingMembersTable
 */
export default {
  name: 'PendingMembersTable',

  mixins: [DatetimeMixin],

  props: {
    brandId: {
      type: String,
      default: ''
    },
    invitations: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      isCancelling: false,
      isResending: false
    }
  },
  methods: {
    async cancelInvitation (invitation) {
      try {
        await this.$confirm(this.$t('components.pending_members_table.confirm_invitation_cancel'))
        this.isCancelling = invitation.id
        await BrandApiService.deleteInvitation(this.brandId, invitation.id)
        this.removeInvitation(invitation)
      } catch (e) {
        if (e === 'cancel') return
        this.$displayRequestError(e)
      } finally {
        this.isCancelling = false
      }
    },
    removeInvitation (invitation) {
      this.$emit('update:invitations', this.invitations.filter(i => i.id !== invitation.id))
    },
    async resendInvitation (invitation) {
      try {
        await this.$confirm(this.$t('components.pending_members_table.confirm_resend'))
        this.isResending = invitation.id
        await BrandApiService.resendInvitation(this.brandId, invitation.id)
        this.$emit('resend')
        this.$notify.success(this.$t('components.pending_members_table.resend_success', { email: invitation.email }))
      } catch (err) {
        if (err === 'cancel') return
        this.$displayRequestError(err)
      } finally {
        this.isResending = false
      }
    }
  }
}
</script>
